//
import React, { useState, useEffect } from 'react'

/**
 * Passes window.Routes variable as a property to React component.
 * This abstraction is needed so we could easily switch routing providers in the
 * future if needed and not depend on window.Routes property.
 *
 * @param Component
 * @returns {React.Component}
 */
export default function railsRoutes(Component) {
    return function WrapperComponent(props) {
        return <Component {...props} routes={window.Routes} />
    }
}

export function useRailsRoutes() {
    const [routes, setRoutes] = useState(window.Routes)

    useEffect(() => {
        setRoutes(window.Routes)
    }, [window.Routes])

    return routes
}

export const getRailsRoutes = () => window.Routes
